.grid-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.25rem; /* 10px เมื่อแปลเป็น rem (1 rem = 10px) */
}

@media (min-width: 640px) {
  .grid-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1536px) {
  .grid-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.header-aria {
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.header-text {
  flex: 1;
  text-align: center;
  font-weight: 700;
}

.title {
    font-weight: 700;
    color: #F7980A;
}

/* .text-hidden {
  height: 40;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} */

.line {
  width: 100%;
  height: 1px;
  background-color: #b5b3b3; /* สีของเส้น */
}

.modal-step {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 30px;
}

.box-header {
  background-color: #F7980A;
  border: 1px solid #F7980A;
  width: auto;
  margin: 5px;
  padding: 2px;
  text-align: center;
  border-radius: 17px;
}

.text-underline {
  text-decoration: underline #000000; 
  text-decoration-thickness: 2px; 
  text-underline-offset: 5px; 
}

.arrow-right {
  border-left: 10px solid transparent;
  border-right: 0;
  border-bottom: 10px solid #F7980A;
}